
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
//import   helperFile   from '../../../helpers/helper';

import {
 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "User Details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
      Multiselect,
      Layout,
      PageHeader,
    
  },
  data() {
    return {
      title: "User Details",
      items: [
        {
          text: "User Details",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],



     emailID:'',
          mobileNo:'',
          isCorporateUser:'',
          corporateDetails:'',
          userAddress:[],
          ordersDetails:[],
          userAddressData:'',
          OrdersShimmer:0,
        //   orderStatus:''
   
    };
  },
    mounted() {
        // if(this.$route.params.profileType){
        //         this.profileType = this.$route.params.profileType;
        //     }
        if(this.$route.params.id){
            sessionStorage.setItem('userID',this.$route.params.id);// Save to sessionStorage
        }
         this.userID =  sessionStorage.getItem('userID');
      
       if (this.userID !== null) {
           this.getUserDataByID(this.userID);
			
		}
        if(this.isCorporateUser == 1)
        {
            this.getCorporateDetails();
        }
        this.getUserAddressProfile();
        this.getOrdersByUserID();
    },
  methods:{
    // pushPofile(profileTypeTab)
    // {
    //     console.log(profileTypeTab);
    //     // this.$router.push('/profile/userProfile/'+profileTypeTab);
    // },
    getUserDataByID(userID){
        this.axios.post('/getUserDataByID', {'userID':userID})
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {                   
                    this.userName = res.data.data.userName;
                    this.emailID = res.data.data.emailID;
                    this.mobileNo = res.data.data.mobileNo;
                    this.isCorporateUser = res.data.data.isCorporateUser;
                    this.corporateCode = res.data.data.corporateCode;
                    this.corporateName = res.data.data.corporateName;
                }else{
                    //console.log(res.data.message);
                }
            });
    },
    getCorporateDetails(){        
            
            let getCorpData = JSON.stringify({ userID: this.userID,
                                            });

            this.axios.get('/getCorporateDetails', getCorpData)
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.corporateDetails = res.data.data;
                }else{
                    console.log(res.data.message);
                }
            });
        },
        getUserAddressProfile()
        {
            let getAddressesData = JSON.stringify({ userID: this.userID,
                                            });

            this.axios.post('getUserAddress', getAddressesData)
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.userAddress = res.data.data;
                }else{
                    console.log(res.data.message);
                }
            });
        },
        getOrdersByUserID()
        {
            let getOrdersData = JSON.stringify({ userID: this.userID,
                                            });

            this.axios.post('getUserOrdersByID', getOrdersData)
            .then((res)=>{
                this.OrdersShimmer = 1;
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.ordersDetails = res.data.data;
                }else{
                    console.log(res.data.message);
                }
            });
        },
        addressModal()
        {
            this.userAddressData = "";
            this.$bvModal.show("modal-addAddress");
        },
        editAdress(userAddID)
        {
            // alert(userAddID);
            let getUserAddressData = JSON.stringify({ userID: this.userID,
                                                      userAddressID: userAddID
                                            });

            this.axios.post('/getUserAddressByAddID', getUserAddressData)
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    // this.editAddressByID = deliveryAddressPopup.methods.idForEditAddress;
                    // this.editAddressByID(res.data.data);
                    this.userAddressData = res.data.data;
                    this.$bvModal.show("modal-addAddress");
                }else{
                    console.log(res.data.message);
                }
            });
            // this.editAddressByID = deliveryAddressPopup.methods.idForEditAddress;
            // this.editAddressByID(userAddID);
            // this.$bvModal.show("modal-addAddress");
        },
        removeAddress(userAddID)
        {
            let getUserAddressData = JSON.stringify({ userID: this.userID,
                                                      userAddressID: userAddID
                                            });

            this.axios.post('/removeUserAddress', getUserAddressData)
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    alert('Address deleted Successfully');
                    location.reload();
                    // this.editAddressByID = deliveryAddressPopup.methods.idForEditAddress;
                    // this.editAddressByID(res.data.data);
                    // this.$bvModal.show("modal-addAddress");
                }else{
                    console.log(res.data.message);
                }
            });
        }

  },


  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
<main>
    <div class="mainDiv">
        <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2 tabsPostion"
              content-class="pt-0 px-2 text-muted"
            >
            <b-tab title="Orders" :active="profileType == 'my-orders'" title-item-class="mb-2 pills-li">
                <b-card-text>
            <div class="section-services container">
                <div class="reviews">
                    <div class="">
                            <div class="row">
                            <div class="col-md-6 review_content">
                                <div class="clearfix">
                                    <h4 class="cardTitle">Orders</h4>
                                </div>
                            </div>
                            
                        </div>
                            <!-- /row -->

                            <!-- <div class="row reply"  v-if="ordersDetails.length > 0">
                                <div class="col-md-6 otherAdd" v-for="orderDetailsobj in ordersDetails" v-bind:key="orderDetailsobj">
                                    <div class="deliveryCard align-left">
                                        <div class="orderTotalSum">
                                            <h5>Order ID: {{orderDetailsobj.orderID}}</h5>
                                        </div>

                                        <div class="totalOrderSum">
                                            <p class="">₹{{orderDetailsobj.orderTotalSum}}</p>
                                        </div>
                                        <div class="address">
                                            <span class="placedOn">Placed on: {{orderDetailsobj.placedOn}}</span>
                                        </div>
                                        <div class="row orderStatus_ViewOrder">
                                            <p class="col-6 orderStatus" :style="{color : orderDetailsobj.statusColor}">{{orderDetailsobj.orderStatus}}</p>
                                            
                                            <div class="col-6 align-right">
                                                <a :href="'/orderDetails?id='+orderDetailsobj.statusColor+'?od=1'">
                                                    <button class="btn_1">View Details</button>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> -->
                            <section v-if="OrdersShimmer == 0">
                                <div class="row">
                                    <div class="col-md-6" v-for="n in 10" v-bind:key="n">
                                    <div class="strip">
                                        <a class="menu_item modal_dialog">
                                            <VShimmerLine width="16" heightPx="24" style="position: absolute;bottom:43px;right:20px;" />
                                            <h3><VShimmerLine width="40" /></h3>
                                            <VShimmerLine width="20" />
                                            <p style="margin-top:15px;" class="two-line-text"><VShimmerLine width="50" /></p>
                                            <br>
                                        <VShimmerLine width="8" />
                                        </a>
                                    </div>
                                    </div>
                                    
                                </div>
                                <!-- /row -->
                            </section>
                            <!-- /section -->
                            <section class="ordersSection"  v-if="ordersDetails.length > 0 && OrdersShimmer == 1">
                                <div class="row">
                                    <div  class="col-md-6" v-for="orderDetailsobj in ordersDetails" v-bind:key="orderDetailsobj">
                                        <div class="strip">
                                            <a class=" card menu_item">
                                                <h5>Order ID: {{orderDetailsobj.orderID}}</h5>
                                                <p class="orderTotalSum">₹{{orderDetailsobj.orderTotalSum}}</p>
                                                <span class="placedOn">Placed on: {{orderDetailsobj.placedOn}}</span>
                                                <div class="row orderStatus_ViewOrder">
                                                    <p class="col-6 orderStatus" :style="{color : orderDetailsobj.statusColor}">{{orderDetailsobj.orderStatus}}</p>
                                                    
                                                    <!-- <div class="col-6 align-right">
                                                        <a :href="'/orderDetails/viewDetails?id='+orderDetailsobj.orderID+'?od=1'">
                                                            <button class="btn btn-themeBrown view_details">View Details</button>
                                                        </a>
                                                    </div> -->
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </b-card-text>
            </b-tab>
            <!-- tab end -->

            <b-tab title="Subscriptions" :active="profileType == 'my-subscriptions'" title-item-class="mb-2 pills-li">
            <b-card-text>
                    <div class="section-services container">
                    <div class="comingSoonCard">
                        <h1 class="comingSoonText align-center">Coming Soon</h1>
                    </div>
                </div>
            </b-card-text>
            </b-tab>
            <!-- tab end -->

            <b-tab title="Profile" :active="profileType == 'my-profile'" title-item-class="mb-2 pills-li">
            <b-card-text>
                <div class="section-services container">
                    <!-- <div class="align-right">
                        <button class="btn btn-danger btnNewAdd">Edit</button>
                    </div> -->
                    
                    <table border="1" class="custTable" style="min-width: 500px;">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p>Name</p>
                                            </td>
                                            <td>
                                                <p>{{userName}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Email</p>
                                            </td>
                                            <td>
                                                <p>{{emailID}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Mobile Number</p>
                                            </td>
                                            <td>
                                                <p>{{mobileNo}}</p>
                                            </td>
                                        </tr>

                                        <tr v-if="isCorporateUser == 1">
                                            <td>
                                                <p>Corporate Code</p>
                                            </td>
                                            <td>
                                                <p>{{corporateCode}}</p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <p>Corporate Name</p>
                                            </td>
                                            <td>
                                                <p>{{corporateName}}</p>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                    </table>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->
              <b-tab title="Address" :active="profileType == 'my-address'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <div class="reviews">
                            <div class="review_card">

                                <div class="row">
                                <div class="col-md-6 review_content">
                                    <div class="clearfix">
                                        <h3 class="cardTitle">Address</h3>
                                    </div>
                                </div>

                                <!-- <div class="col-md-6 review_content align-right">
                                    <div class="clearfix">
                                        <button class="btn btn-danger btnNewAdd" @click="addressModal">+ Add New Address</button>
                                    </div>
                                </div> -->
                                
                            </div>
                            <!-- /row -->

                            <div class="row reply"  v-if="(userAddress != '' || userAddress.length != 0)">
                                <div class="col-md-6 otherAdd" v-for="addresses in userAddress" v-bind:key="addresses">
                                    <div class="deliveryCard align-left">
                                        <div class="addressType">
                                            <h5>{{addresses.deliveryAddressType}}</h5>
                                        </div>
                                        <!-- / address Type -->

                                        <div class="address">
                                            <p class="addressText">{{addresses.deliveryAddress}}</p>
                                        </div>
                                        <!--  /address -->
                                        <!-- <div class="row orderStatus_ViewOrder">
                                            <a class="col-6 customAnch" @click="removeAddress(addresses.userAddID)">Remove</a>
                                            
                                            <!-- <div class="col-6 align-right">
                                                    <a class="customAnch" @click="editAdress(addresses.userAddID)">Edit</a>
                                            </div> -->
                                    <!-- </div> --> 
                                        <!-- <div class="deliverBtn align-right">
                                            <a class="customAnch" @click="editAdress(addresses.userAddID)">Edit</a>
                                        </div> -->

                                    </div>
                                    <!-- / delivery Card -->
                                </div>
                                <!-- /col -->
                            </div>
                            <!-- /reply -->
                        </div>
                    </div>
                </div>
            </b-card-text>
            </b-tab>
            <!-- tab end -->

        </b-tabs>
    </div>
</main>

</Layout>
</template>

<style>

.tabs{
    width: 100%;
    background: #ffffff;
}
.setionTitle {
    font-size: 30px;
    margin: 0 0 50px 0;
    color: #222222;
}
.nav-pills{
    margin-top: 40px!important;

}
.tab-content{
    margin: 30px 0px!important;
    border-left: 1px solid #f1f1f1

}
.card-text{
    color: #222222;
}
.policyHead
{
    margin: 35px 0;
    margin-bottom: 10px;
    color: #A4827D;
    font-size: 24px;
}
ul, li{
    text-decoration: none;
    list-style: none;
    padding-left: 0px;
}
li h4{
    color: #A4827D;
    margin-top: 20px;
    margin-bottom: 5px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
        color: #000;
    background-color: #ffc60c;
}
.section-services p{
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 14px;
}
.section-services a{
    color: #F3766A;
}
.custTable {
    border: 1px solid #f1f1f1;
    margin-top: 30px;
    margin-bottom: 30px;
}
.custTable tr th {
    background-color: #f1f1f1;
    text-align: left;
}
table, tr, td, th {
    border-spacing: 0;
    padding: 0;
    margin: 0;
    vertical-align: top;
}
tr:nth-child(even) {
  background-color: #f8f9fa;
}
.custTable tr th, .custTable tr td {
    padding: 15px;
    border: 1px solid #f1f1f1;
}
.process-table{
    width: 100%;
}
.mainDiv
{
    padding: 50px;
}
.review_card{
            padding:25px 25px 25px 25px;
        }
        .deliveryCard{
            border:1px solid #ededed;
            border-radius:3px;
            padding: 20px;
        }
        .addressType h5{
            font-size:16px;
        }
        .address span{
            font-size:14px;
        }
        .deliverBtn{
            margin-top:17px;
        }
        .btn_1{
            background:#A4827D;
        }
        .disabledBtn{
            background:rgba(52, 58, 64, 0.25) !important;
			cursor: not-allowed;
			color: #212121!important;
			
        }
        .btnNewAdd{
            background: #F3766A;
        }
        .btnSave{
                width: 85px;
                height: 35px;
        }
        .otherAdd{
            margin-top:20px;
		}
        .comingSoonCard
        {
            border: 1px solid #f0f0f0;
        }
        .comingSoonText
        {
            margin: 75px;
        }
        .menu_item .orderTotalSum
        {
            color: #222!important;
        }
        .placedOn
        {
            font-size: 12px;
            color: #777777;
        }
        .orderStatus_ViewOrder
        {
            margin-top: 10px;
        }
        .view_details
        {
            padding: 8px 15px!important;
            width: fit-content;
        }
        .customAnch
        {
            cursor: pointer;
        }
        .ordersSection
        {
            padding-top: 15px;
        }
        .addressText
        {
            line-height: 22px;
            color: #777777;
        }
        .pills-li a
        {
            padding: 1rem 1rem;
        }
        .tabs
        {
            min-height: 500px;
        }
@media (min-width: 575px){
    .tabsPostion{
        /* border-right: 1px solid #f1f1f1; */
        position: sticky;
        top: 90px;
        height: 100%;
        padding-left: 20px;
    }
}
</style>
<style scoped>
a.menu_item
{
    box-shadow: 0px 0px 24px 0px rgb(46 51 51 / 5%);
    display: block;
    position: relative;
    padding: 25px 20px 20px 20px;
    color: #444;
    margin-bottom: 30px;
}
a.btn_1:hover, .btn_1:hover
{
    background: #F3766A;
}
</style>


